import React from "react";
import { Container, Typography, Box } from "@mui/material";

function Projects() {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 8 }}>
        <Typography variant="h4" gutterBottom>
          Projects
        </Typography>
        <Typography variant="body1">
          Here you can find some of my recent works and projects.
        </Typography>
      </Box>
    </Container>
  );
}

export default Projects;
