import React from "react";
import { Container, Typography, Box } from "@mui/material";

function About() {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 8 }}>
        <Typography variant="h4" gutterBottom>
          About Me
        </Typography>
        <Typography variant="body1">
          I am a Full Stack Developer with a passion for cyber security.
          Currently, I am studying to become a Cyber Security Engineer.
        </Typography>
      </Box>
    </Container>
  );
}

export default About;
