import React from "react";
import { Container, Typography, Box } from "@mui/material";

function Home() {
  return (
    <Container maxWidth="md">
      <Box textAlign="center" sx={{ mt: 8 }}>
        <Typography variant="h4" gutterBottom>
          Welcome to my personal website!
        </Typography>
        <Typography variant="h6">
          I am Fuat Görmez, a Full Stack Developer and Cyber Security Engineer
          student.
        </Typography>
      </Box>
    </Container>
  );
}

export default Home;
